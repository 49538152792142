<template>
  <div class="pages">
    <div class="pages-top">
      <dl v-for="(item, index) in pagesTopDatas"
          :key="index">
        <dd :style="{ background: item.color }">
          <i :class="item.icon"></i>
        </dd>
        <dt>
          <h3>{{ item.name }}</h3>
          <p>
            <span>{{ item.num }}</span>{{ item.unit }}
          </p>
        </dt>
      </dl>
    </div>
    <div class="pages-content">
      <div class="content-left content">
        <div class="left-top">
          <div :class="leftActive ? 'dateBox leftActive' : 'dateBox'">
            <div class="dateTimes">
              <i class="el-icon-caret-left"
                 @click="dayTimeAction('pro')"></i>
              <span>{{ date[0] }} ~ {{ date[1] }}</span>
              <i class="el-icon-caret-right"
                 @click="dayTimeAction('add')"></i>
              <i class="iconfont icon-rili-icon"></i>
            </div>
            <el-date-picker v-model="date"
                            type="daterange"
                            class="date"
                            @focus="rightFocus(1)"
                            @blur="rightBlur(1)"
                            :clearable="false"
                            @change="timeChange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="dateType"
               style="display: none">
            <div :class="dateType === 1 ? 'active' : ''"
                 @click="tabs(1)">
              日计划
            </div>
            <div :class="dateType === 2 ? 'active' : ''"
                 style="b"
                 @click="tabs(2)">
              其他计划
            </div>
          </div>
        </div>
        <p class="zqName">周训练计划统计</p>
        <div class="left-cen"
             v-show="flag"
             id="leftCen"></div>
        <div class="left-cen"
             v-show="flag"
             id="leftBot"></div>
        <div id="left_other"
             v-show="!flag"></div>
      </div>
      <div class="content-right content">
        <div class="right-top">
          <div :class="rightActive ? 'dateBox rightActive' : 'dateBox'">
            <div>
              <i class="el-icon-caret-left"
                 @click="weDayTimeAction('pro')"></i>
              <span>{{ departmentDate[0] }} ~ {{ departmentDate[1] }}</span>
              <i class="el-icon-caret-right"
                 @click="weDayTimeAction('add')"></i>
              <i class="iconfont icon-rili-icon"></i>
            </div>
            <el-date-picker v-model="departmentDate"
                            type="daterange"
                            class="date"
                            :clearable="false"
                            @focus="rightFocus(2)"
                            @blur="rightBlur(2)"
                            @change="departmentChange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="dateType">
            <el-cascader v-model="departmentValue"
                         :options="options"
                         collapse-tags
                         clearable
                         @change="departmentSelect"
                         :show-all-levels="false"
                         :props="{
                value: 'uuid',
                label: 'name',
                multiple: true,
                emitPath: false
              }"></el-cascader>
          </div>
        </div>
        <p class="zqName">训练监控测试类型占比统计</p>
        <div class="left-cen"
             id="rightTop"></div>
        <p class="zqName">训练评估测试类型次数统计</p>
        <div class="left-cen position">
          <div id="rightBot"
               style="width: 100%; height: 100%"></div>
          <p>共{{sum_all}}次</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      flag: true,
      sum_all: 0,
      pagesTopDatas: [
        {
          icon: 'iconfont icon-bumen1',
          name: '运动队总数',
          color: '#4BB3FF',
          num: '',
          unit: '个',
          ename: 'department'
        },
        {
          icon: 'iconfont icon-renyuanzongshu1',
          name: '人员总数',
          color: '#F2637B',
          num: '',
          unit: '人',
          ename: 'staff'
        },
        {
          icon: 'iconfont icon-xunlianjihuashu',
          name: '训练计划总数',
          color: '#1DC4A5',
          num: '',
          unit: '个',
          ename: 'plan'
        },
        {
          icon: 'iconfont icon-jinengjiankongshu',
          name: '训练监控测试总数 ',
          color: '#F5A623',
          num: '',
          unit: '次',
          ename: 'train_check'
        },
        {
          icon: 'iconfont icon-pinggu',
          color: '#BB84E2',
          name: '训练评估测试总数',
          num: '',
          unit: '次',
          ename: 'train_test'
        }
      ],
      dateType: 1,
      date: [],
      value: '',
      options: [],
      departmentDate: [],
      departmentValue: '',
      leftActive: false,
      rightActive: false
    };
  },
  created () {
    this.$nextTick(function () {
      this.topData();
      this.create();
    });
  },
  watch: {},
  methods: {
    // 首页顶部数据
    topData () {
      // 顶部数据
      this.$axios
        .post('/p/home/head')
        .then(res => {
          if (res.data.code == 0) {
            this.pagesTopDatas.forEach(item => {
              for (var i in res.data.data) {
                if (item.ename == i) {
                  item.num = res.data.data[i];
                }
              }
            });
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
      // 运动队选择
      this.$axios
        .post('/p/staff/tree')
        .then(res => {
          if (res.data.code == 0) {
            this.options = res.data.data.department;
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          }
        })
        .catch(error => {
          console.log(error);
        });

      var beginTime = '';
      var endTime = '';

      var startDate = new Date();

      beginTime += startDate.getFullYear() + '-';
      beginTime +=
        startDate.getMonth() + 1 >= 10
          ? startDate.getMonth() + 1 + '-'
          : '0' + (startDate.getMonth() + 1) + '-';

      beginTime +=
        startDate.getDate() >= 10
          ? startDate.getDate()
          : '0' + startDate.getDate();

      var endDate = new Date(new Date().getTime() - 3600 * 1000 * 24 * 90);

      endTime += endDate.getFullYear() + '-';
      endTime +=
        endDate.getMonth() + 1 >= 10
          ? endDate.getMonth() + 1 + '-'
          : '0' + (endDate.getMonth() + 1) + '-';

      endTime +=
        endDate.getDate() >= 10 ? endDate.getDate() : '0' + endDate.getDate();

      this.departmentDate[0] = endTime;
      this.departmentDate[1] = beginTime;
      this.date[0] = endTime;
      this.date[1] = beginTime;
      this.dateData(endTime, beginTime);
      this.departmentFunc(endTime, beginTime);
    },
    // 上一天 下一天
    dayTimeAction (type) {
      this.date = [
        this.dayTimeConversion(this.date[0], type),
        this.dayTimeConversion(this.date[1], type)
      ];
      this.dateData(this.date[0], this.date[1]);
    },
    weDayTimeAction (type) {
      this.departmentDate = [
        this.dayTimeConversion(this.departmentDate[0], type),
        this.dayTimeConversion(this.departmentDate[1], type)
      ];
      this.departmentFunc(this.departmentDate[0], this.departmentDate[1]);
    },
    dayTimeConversion (time, type) {
      let date = null;
      if (type == 'add') {
        date = new Date(new Date(time).getTime() + 24 * 60 * 60 * 1000);
      } else {
        date = new Date(new Date(time).getTime() - 24 * 60 * 60 * 1000);
      }
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1);
      let d = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
      return y + '-' + m + '-' + d;
    },
    // 日期选择
    timeChange (value) {
      this.dateData(value[0], value[1]);
    },
    // 日 周 月 年
    tabs (value) {
      this.flag = value == 1 ? true : false
      this.dateType = value;
      this.dateData(this.date[0], this.date[1]);
    },
    // 获取数据
    dateData (start, end) {
      // 左侧数据
      this.$axios
        .post(
          '/p/home/drillPlan',
          this.$qs({
            start_date: start,
            end_date: end,
            type: this.dateType
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.create(res.data.data);
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 运动队日期
    departmentChange (value) {
      console.log(value);
      this.departmentFunc(value[0], value[1]);
    },
    // 运动队选择
    departmentSelect (type) {
      this.departmentFunc(this.departmentDate[0], this.departmentDate[1]);
    },
    departmentFunc (start, end) {
      var str = '';
      if (this.departmentValue.length >= 1) {
        this.departmentValue.forEach((item, index) => {
          if (index == this.departmentValue.length - 1) {
            str += item;
          } else {
            str += item + ',';
          }
        });
      }
      this.$axios
        .post(
          '/p/home/train',
          this.$qs({
            start_date: start,
            end_date: end,
            department_uuid: str
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.rightTed(res.data.data);
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 左侧echarts
    create (datas) {
      if (this.flag) {
        var x = [],
          feedback_num = [],
          plan_num = [],
          skill = [],
          physical = [],
          Intelligent = [],
          regeneration = [];
        if (datas !== undefined) {
          datas.forEach(item => {
            feedback_num.push(Number(item.feedback_num));
            plan_num.push(item.plan_num);
            x.push(item.name)
            skill.push(item.jineng_num);
            physical.push(item.tineng_num);
            Intelligent.push(item.zhineng_num);
            regeneration.push(item.zaisheng_num);
          });
        }

        let maxNumB = Math.max(...skill.concat(physical).concat(Intelligent).concat(regeneration))
        let maxNum = Math.max(...plan_num.concat(feedback_num))
        var myChart = this.$echarts.init(document.getElementById('leftCen'));
        var option = {
          title: {
            show: false,
            text: '周期训练计划数目统计',
            textStyle: {
              fontWeight: 400,
              fontSize: 14,
              color: '#f00'
            }
          },
          //实现拖拽效果
          dataZoom: [
            {
              type: 'slider',
              realtime: true, //拖动滚动条时是否动态的更新图表数据
              height: 25, //滚动条高度
              start: 0, //滚动条开始位置（共100等份）
              endValue: 4, //结束位置（共100等份）
              show: false //是否显示该工具。
            },
            {
              type: 'inside',
              zoomOnMouseWheel: false //触发缩放
            }
          ],
          tooltip: {},
          xAxis: {
            data: x,
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#ccc'
              }
            },
            nameTextStyle: {
              color: '#000'
            },
            axisLabel: {
              align: 'center',
              verticalAlign: 'top',
              color: '#666666',
              height: 'auto',
              formatter: function (value) {
                //只显示五个字 其余省略号
                if (!value) return;
                return value.length > 5 ? value.substring(0, 5) + '...' : value;
              }
            }
          },
          grid: {
            top: '15%',
            bottom: '24%',
            left: '7%'
          },
          yAxis: [
            {
              type: 'value',
              name: '单位：个',
              // min: 5,
              max: maxNum < 5 ? 5 : maxNum,
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                lineStyle: {
                  type: 'dashed'
                }
              },
              nameTextStyle: {
                fontSize: '12',
                align: 'center',
                color: '#666666'
              }
            }
          ],
          legend: {
            data: ['计划数', '反馈数'],
            top: '3%',
            icon: 'roundRect',
            align: 'auto',
            height: 10,
            itemWidth: 7, // 设置宽度
            itemHeight: 7, // 设置高度
            textStyle: {
              color: '#666666'
            }
          },
          color: ['#4BB3FF', '#F5A623'],
          series: [
            {
              name: '计划数',
              type: 'bar',
              barWidth: 10,
              data: plan_num,
              label: {
                show: true,
                position: 'top'
              },
              itemStyle: {
                color: '#4BB3FF'
              }
            },
            {
              name: '反馈数',
              type: 'bar',
              barWidth: 10,
              data: feedback_num,
              label: {
                show: true,
                position: 'top'
              },
              itemStyle: {
                color: '#F5A623'
              }
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
        this.$echartsResize(myChart);

        // 折线
        var zxmyChart = this.$echarts.init(document.getElementById('leftBot'));
        var zxoption = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['技能训练', '体能训练', '智能训练', '再生训练'],
            top: '4%',
            icon: 'roundRect',
            align: 'auto',
            height: 10,
            itemWidth: 20, // 设置宽度
            itemHeight: 5, // 设置高度
            textStyle: {
              color: '#666666'
            }
          },
          grid: {
            right: '10%',
            top: '15%',
            bottom: '5%',
            left: '5%',
            containLabel: true
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {}
          //   }
          // },
          //实现拖拽效果
          dataZoom: [
            {
              type: 'slider',
              realtime: true, //拖动滚动条时是否动态的更新图表数据
              height: 25, //滚动条高度
              start: 0, //滚动条开始位置（共100等份）
              endValue: 4, //结束位置（共100等份）
              show: false //是否显示该工具。
            },
            {
              type: 'inside',
              zoomOnMouseWheel: false //触发缩放
            }
          ],
          xAxis: {
            type: 'category',
            boundaryGap: 'auto',
            data: x,
            align: 'center',
            // axisTick: {
            //   show: false,
            //   interval: 'auto'
            // },
            nameLocation: 'end',
            axisLabel: {
              align: 'center',
              verticalAlign: 'top',
              color: '#666666',
              formatter: function (value) {
                //只显示五个字 其余省略号
                if (!value) return;
                return value.length > 5 ? value.substring(0, 5) + '...' : value;
              }
            },
            axisLine: {
              lineStyle: {
                color: '#E1E1E1'
              }
            },
            maxInterval: 1
          },
          color: ['#4BB3FF', '#FACC14', '#1DBB9D', '#FF7EA7'],
          yAxis: {
            type: 'value',
            name: '单位：个',
            max: maxNumB < 5 ? 5 : maxNumB,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            nameTextStyle: {
              fontSize: '12',
              align: 'center',
              color: '#666666'
            },
            axisLabel: {
              color: '#666666'
            }
          },

          series: [
            {
              name: '技能训练',
              type: 'line',
              data: skill,
            },
            {
              name: '体能训练',
              type: 'line',
              data: physical,
            },
            {
              name: '智能训练',
              type: 'line',
              data: Intelligent,

            },
            {
              name: '再生训练',
              type: 'line',
              data: regeneration,
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        zxmyChart.setOption(zxoption);
        this.$echartsResize(zxmyChart);
      } else {
        let month_num = [],
          x = [],
          week_num = [],
          year_num = []
        datas.forEach(item => {
          month_num.push(item.month_num)
          week_num.push(item.week_num)
          year_num.push(item.year_num)
          x.push(item.name)
        })

        var zxmyChart = this.$echarts.init(document.getElementById('left_other'));
        var zxoption = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['周计划', '月计划', '年计划'],
            top: '4%',
            icon: 'roundRect',
            align: 'auto',
            height: 10,
            itemWidth: 20, // 设置宽度
            itemHeight: 5, // 设置高度
            textStyle: {
              color: '#666666'
            }
          },
          grid: {
            right: '10%',
            top: '15%',
            bottom: '5%',
            left: '5%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          //实现拖拽效果
          dataZoom: [
            {
              type: 'slider',
              realtime: true, //拖动滚动条时是否动态的更新图表数据
              height: 25, //滚动条高度
              start: 0, //滚动条开始位置（共100等份）
              endValue: 4, //结束位置（共100等份）
              show: false //是否显示该工具。
            },
            {
              type: 'inside',
              zoomOnMouseWheel: false //触发缩放
            }
          ],
          xAxis: {
            type: 'category',
            boundaryGap: 'auto',
            data: x,
            align: 'center',
            axisTick: {
              show: false,
              interval: 'auto'
            },
            nameLocation: 'end',
            axisLabel: {
              align: 'center',
              verticalAlign: 'top',
              color: '#666666',
              formatter: function (value) {
                //只显示五个字 其余省略号
                if (!value) return;
                return value.length > 5 ? value.substring(0, 5) + '...' : value;
              }
            },
            axisLine: {
              lineStyle: {
                color: '#E1E1E1'
              }
            },
            maxInterval: 1
          },
          color: ['#4BB3FF', '#FACC14', '#1DBB9D'],
          yAxis: {
            type: 'value',
            name: '单位：个',
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },

            axisLine: {
              lineStyle: {
                color: '#E1E1E1'
              }
            },
            axisLabel: {
              color: '#666666',
            },
            nameTextStyle: {
              fontSize: '12',
              align: 'center',
              color: '#666666'
            },
          },

          series: [
            {
              name: '周计划',
              type: 'line',
              data: week_num,
              symbol: 'none'
            },
            {
              name: '月计划',
              type: 'line',
              data: month_num,
              symbol: 'none'
            },
            {
              name: '年计划',
              type: 'line',
              data: year_num,
              symbol: 'none'
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        zxmyChart.setOption(zxoption);
        this.$echartsResize(zxmyChart);
      }

    },
    // 右侧echarts
    rightTed (data) {
      var lins = [];
      let num = 0
      data.train_check.forEach(item => {
        var obj = {};
        obj.name = item.name;
        obj.value = item.num;
        num += item.num
        obj.percent = item.percent;
        lins.push(obj);
      });
      console.log(num)
      let myChart = this.$echarts.init(document.getElementById('rightTop'));
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        graphic: {       //图形中间文字
          type: "text",
          left: "center",
          top: "center",
          style: {
            text: "66",
            textAlign: "center",
            fill: "#fff",
            fontSize: 60
          }
        },
        legend: {
          orient: 'vertical',
          icon: 'circle',
          radius: '10%',
          height: 'auto',
          trigger: 'item',
          itemHeight: 10,
          //          data: data.series,
          formatter: function (name) {
            for (let i in lins) {
              if (lins[i].name === name) {
                return (
                  '{a|' +
                  name +
                  '  }{b|' +
                  (lins[i].value ? lins[i].value + '次' : '--') +
                  '  }{c|' +
                  (lins[i].percent !== '0%' ? lins[i].percent : '--') +
                  '}'
                );
              }
            }
          },
          textStyle: {
            rich: {
              a: {
                fontFamily: 'MicrosoftYaHei',
                color: 'rgba(102, 102, 102, 1)',
                lineHeight: 27,
                width: 70
              },
              b: {
                fontFamily: 'MicrosoftYaHei',
                color: 'rgba(136,136,136,1)',
                lineHeight: 27,
                textAlign: 'right',
                width: 50
              },
              c: {
                fontFamily: 'MicrosoftYaHei',
                color: 'rgba(51,51,51,1)',
                lineHeight: 27,
                textAlign: 'right',
                width: 50
              }
            }
          },
          show: true,
          right: '10%',
          top: '20%'
        },
        //         设置边框颜色
        color: ['#4BB3FF', '#1DBB9D', '#FFCD58', '#FF7EA7', '#FC940A'],
        grid: {
          // right: '5%', // 与容器右侧的距离
          top: '5%', // 与容器顶部的距离
          bottom: '12%', // 与容器底部的距离
          left: '10%'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '60%'],
            label: {
              normal: {
                show: true,
                position: 'center',
                color: '#4c4a4a',
                formatter: '共' + num + '次',
                rich: {
                  total: {
                    fontFamily: 'MicrosoftYaHei',
                    color: 'rgba(141,141,141,1)',
                    fontSize: '16',
                    lineHeight: 28
                  },
                  active: {
                    fontFamily: 'MicrosoftYaHei',
                    fontSize: '18',
                    color: '#333333'
                  }
                },
                emphasis: {
                  // 中间文字显示
                  show: true
                }
              }
            },
            center: ['25%', '45%'], // 设置饼图位置
            labelLine: {
              normal: {
                show: false
              }
            },
            data: lins
          }
        ]
      };
      myChart.setOption(option);
      this.$echartsResize(myChart);

      var botData = data.train_test;
      var name = [],
        values = [];
      this.sum_all = 0
      botData !== undefined &&
        botData.forEach(item => {
          this.sum_all += item.num
          name.unshift(item.name);
          values.unshift(item.num);
        });

      var zxmyChart = this.$echarts.init(document.getElementById('rightBot'));
      var options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '10%',
          bottom: '5%',
          top: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: '次',
          boundaryGap: [0, 0.01],
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          },
          axisLabel: {
            color: '#666666',
            fontStyle: 'normal'
          },
          nameTextStyle: {
            color: '#666666'
          }
        },
        yAxis: {
          type: 'category',
          data: name,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#ccc'
            }
          },
          axisLabel: {
            color: '#666666',
            fontStyle: 'normal'
          }
        },
        series: [
          {
            type: 'bar',
            data: values,
            label: {
              show: true,
              position: 'right'
            },
            itemStyle: {
              color: '#4BB3FF'
            },
            barWidth: 10
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      zxmyChart.setOption(options);
      this.$echartsResize(zxmyChart);
    },
    rightFocus (type) {
      if (type == '1') {
        this.leftActive = true;
      } else {
        this.rightActive = true;
      }
    },
    rightBlur (type) {
      if (type == '1') {
        this.leftActive = false;
      } else {
        this.rightActive = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$dateHeight: 36px;

.position {
  position: relative;
  > p {
    position: absolute;
    top: 0;
    right: 30px;
    word-break: break-all;
    color: #666666;
  }
}

#left_other {
  width: 100%;
  height: 700px;
}
.pages {
  width: 100%;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  .pages-top {
    width: 100%;
    display: flex;
    dl {
      flex: 1;
      display: flex;
      margin-right: 20px;
      // padding: 10px;
      background: #fff;
      border-radius: 5px;
      align-items: center;
      overflow: hidden;
      &:last-child {
        margin-right: 0;
      }
      dd {
        width: 30px;
        height: 30px;
        padding: 15px;
        margin-right: 20px;
        margin-left: 30px;
        text-align: center;
        border-radius: 50%;
        float: right;
        i {
          display: inline-block;
          font-size: 25px;
          color: #fff;
        }
      }
      dt {
        flex: 1;
        overflow: hidden;
        margin: 20px 20px 20px 0;
        h3 {
          font-weight: 400;
          // width: 100%;
          min-width: 40px;
          margin-bottom: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          color: #666666;
        }
        p {
          font-size: 16px;
          span {
            font-size: 30px;
            margin-right: 6px;
          }
        }
      }
    }
  }
  .pages-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .content {
      width: 50%;
      background: #fff;
    }
    .content-left {
      margin-right: 20px;
      .left-top {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 1vh 20px;
        margin-bottom: 10px;
        background-color: #ffffff;
        justify-content: space-between;
        .dateType {
          display: flex;
          margin-right: 30px;

          div {
            width: 60px;
            height: $dateHeight;
            background: #fff;
            color: #888;
            border: 1px solid #e5e5e5;
            border-left: 0;
            text-align: center;
            line-height: $dateHeight;
            cursor: pointer;
          }

          div:nth-child(1) {
            border-radius: 4px 0px 0px 4px;
            &:hover {
              border: 1px solid #ccc;
            }
          }

          div:nth-child(2) {
            &:hover {
              border: 1px solid #ccc;
              border-left: none;
              border-radius: 0px 4px 4px 0px;
            }
          }

          div:first-child {
            border-left: 1px solid #e5e5e5;
          }

          div:last-child {
            // border-left: 1px solid #e5e5e5;
            border-radius: 0px 4px 4px 0px;
          }

          div.active {
            color: #1790ff;
            border: 1px solid #1790ff !important;
          }
        }
        .dateBox {
          width: 265px;
          height: $dateHeight;
          background: #fff;
          position: relative;
          margin-right: 30px;
          float: left;
          // text-align: center;
          line-height: $dateHeight;
          color: #999999;
          border: 1px solid #e5e5e5;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          &:hover {
            border: 1px solid #ccc;
          }
          .dateTimes {
            min-width: 260px;
          }
          i {
            margin: 0 10px;
          }

          i.icon-rili-icon {
            margin: 0 4px;
            color: #1e9fff;
          }

          .date {
            left: 20px;
            position: absolute;
            right: 0;
            width: 180px;
            height: $dateHeight;
            top: 0;
            opacity: 0;
          }
        }
      }
      .zqName {
        font-size: 16px;
        border-left: 5px solid #4bb3ff;
        padding-left: 5px;
        margin-left: 20px;
        height: 20px;
        line-height: 20px;
      }
      .left-cen {
        width: 100%;
        height: 350px;
      }
    }
    .content-right {
      .right-top {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 1vh 20px;
        margin-bottom: 10px;
        background-color: #ffffff;
        justify-content: space-between;
        .dateType {
          width: auto;
          margin-right: 30px;
        }
        .dateBox {
          width: 265px;
          height: $dateHeight;
          background: #fff;
          position: relative;
          margin-right: 30px;
          float: left;
          // text-align: center;
          line-height: $dateHeight;
          color: #999999;
          border: 1px solid #e5e5e5;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          &:hover {
            border: 1px solid #ccc;
          }
          .dateTimes {
            min-width: 260px;
          }
          i {
            margin: 0 10px;
          }

          i.icon-rili-icon {
            margin: 0 4px;
            color: #1e9fff;
          }

          .date {
            left: 25px;
            position: absolute;
            right: 0;
            width: 180px;
            height: $dateHeight;
            top: 0;
            opacity: 0;
          }
        }
      }
      .zqName {
        font-size: 16px;
        border-left: 5px solid #4bb3ff;
        padding-left: 5px;
        margin-left: 20px;
        height: 20px;
        line-height: 20px;
      }
      .left-cen {
        width: 100%;
        height: 350px;
      }
    }
  }
}

.leftActive {
  border: 1px solid #1790ff !important;
}
.rightActive {
  border: 1px solid #1790ff !important;
}
</style>

<style>
.contentViewWrap {
  /* padding: 0px 20px 30px !important; */
}
</style>
